<template>
  <button type="button" :class="classes" :disabled="disabled" @click="onClick">
    {{ label }}
    <slot />
  </button>
</template>

<script>
import { reactive, computed } from 'vue'

export default {
  name: 'T2Button',

  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String,
      validator(value) {
        return ['small', 'medium', 'large'].includes(value)
      },
      default: 'large',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],

  setup(props, { emit, attrs }) {
    props = reactive(props)
    let sizeClasses = ''
    if (props.size === 'small') {
      sizeClasses = 'tw-text-xs tw-py-1'
    } else if (props.size === 'medium') {
      sizeClasses = 'tw-text-sm tw-py-2'
    } else if (props.size === 'large') {
      sizeClasses = 'tw-text-base tw-py-4'
    }

    const fallbackBorderColor = computed(() => {
      if (attrs.class?.split(' ').find(c => /tw-border-\w+(?:-\d+)?/.test(c))) {
        return ''
      } else {
        return 'tw-border-slate-500'
      }
    })

    const fallbackBackgroundColor = computed(() => {
      if (attrs.class?.split(' ').find(c => /tw-bg-\w+(?:-\d+)?/.test(c))) {
        return ''
      } else {
        return 'tw-bg-slate-500'
      }
    })

    const fallbackTextColor = computed(() => {
      if (attrs.class?.split(' ').find(c => /tw-text-\w+(?:-\d+)?/.test(c))) {
        return ''
      } else {
        return 'tw-text-white'
      }
    })

    const fallbackHoverColor = computed(() => {
      if (attrs.class?.split(' ').find(c => /tw-bg-\w+(?:-\d+)?/.test(c))) {
        return ''
      } else {
        return 'hover:tw-bg-slate-400'
      }
    })

    return {
      classes: computed(() => ({
        [`${sizeClasses} tw-rounded-lg focus:tw-outline focus:tw-outline-2 focus:tw-outline-offset-2 focus:tw-outline-slate-900 tw-font-medium`]: true,
        [`tw-border ${fallbackBorderColor.value} ${fallbackTextColor.value} ${fallbackBackgroundColor.value} ${fallbackHoverColor.value} tw-cursor-pointer`]:
          !props.disabled,
        '!tw-bg-gray-100 !tw-text-gray-400': props.disabled,
      })),
      onClick() {
        emit('click')
      },
    }
  },
}
</script>
